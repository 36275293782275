import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import Link from '@mui/material/Link';
import { Outlet, Link as RouterLink, useNavigation, useLocation } from "react-router-dom";
import { getAuthToken } from '../../common/utils/auth';
import { useGoogleLogin } from '@react-oauth/google';
import { notification } from "antd";
import { useSubmit } from "react-router-dom";
import { getEmail } from '../../common/utils/auth';
import axios from 'axios';
import LinearProgress from '@mui/material/LinearProgress';
import Copyright from '../../components/Copyright';


const drawerWidth = 120;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(0),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(0),
        },
      }),
    },
  }),
);

const mdTheme = createTheme();

function DashboardContent(props) {
  //handle login/logout state
  const [jwt, setJwt] = React.useState(getAuthToken());

  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const location = useLocation()

  const [openL, setOpenL] = React.useState(() => {
    let ret = false;
    if (location.pathname.includes("binance")) {
      ret = true;
    }
    return ret;
  });
  const [openLKuc, setOpenLKuc] = React.useState(() => {
    let ret = false;
    if (location.pathname.includes("kucoin")) {
      ret = true;
    }
    return ret;
  });
  const [openLOkx, setOpenLOkx] = React.useState(() => {
    let ret = false;
    if (location.pathname.includes("okx")) {
      ret = true;
    }
    return ret;
  });
  const [openLBybit, setOpenLBybit] = React.useState(() => {
    let ret = false;
    if (location.pathname.includes("bybit")) {
      ret = true;
    }
    return ret;
  });
  const [openLGateIO, setOpenLGateIO] = React.useState(() => {
    let ret = false;
    if (location.pathname.includes("gateio")) {
      ret = true;
    }
    return ret;
  });


  let selectedEl = 0;
  if (location.pathname === "/5min/binance/busd") {
    selectedEl = 1;
  }
  if (location.pathname === "/5min/binance/usdt") {
    selectedEl = 0;
  }
  if (location.pathname.includes("/bins/binance/busd")) {
    selectedEl = 2;
  }
  if (location.pathname.includes("/bins/binance/usdt")) {
    selectedEl = 3;
  }
  if (location.pathname.includes("/dailycandles/binance/usdt")) {
    selectedEl = 4;
  }
  if (location.pathname.includes("/dailycandles/binance/busd")) {
    selectedEl = 5;
  }
  if (location.pathname.includes("/5min/kucoin/usdt")) {
    selectedEl = 6;
  }
  if (location.pathname.includes("/bins/kucoin/usdt")) {
    selectedEl = 7;
  }
  if (location.pathname.includes("/dailycandles/kucoin/usdt")) {
    selectedEl = 8;
  }
  if (location.pathname.includes("/5min/okx/usdt")) {
    selectedEl = 9;
  }
  if (location.pathname.includes("/bins/okx/usdt")) {
    selectedEl = 10;
  }
  if (location.pathname.includes("/dailycandles/okx/usdt")) {
    selectedEl = 11;
  }
  if (location.pathname.includes("/5min/bybit/usdt")) {
    selectedEl = 12;
  }
  if (location.pathname.includes("/bins/bybit/usdt")) {
    selectedEl = 13;
  }
  if (location.pathname.includes("/dailycandles/bybit/usdt")) {
    selectedEl = 14;
  }
  if (location.pathname.includes("/5min/gateio/usdt")) {
    selectedEl = 15;
  }
  if (location.pathname.includes("/bins/gateio/usdt")) {
    selectedEl = 16;
  }
  if (location.pathname.includes("/dailycandles/gateio/usdt")) {
    selectedEl = 17;
  }
  const handleClick = () => {
    setOpenL(!openL);
    setOpenLKuc(false);
    setOpenLOkx(false);
    setOpenLBybit(false);
    setOpenLGateIO(false);
  };
  const handleClickKuc = () => {
    setOpenLKuc(!openLKuc);
    setOpenL(false);
    setOpenLOkx(false);
    setOpenLBybit(false);
    setOpenLGateIO(false);
  };
  const handleClickOkx = () => {
    setOpenLOkx(!openLOkx);
    setOpenLKuc(false);
    setOpenL(false);
    setOpenLBybit(false);
    setOpenLGateIO(false);
  };
  const handleClickBybit = () => {
    setOpenLBybit(!openLBybit);
    setOpenLOkx(false);
    setOpenLKuc(false);
    setOpenL(false);
    setOpenLGateIO(false);
  };
  const handleClickGateIO = () => {
    setOpenLGateIO(!openLGateIO);
    setOpenLBybit(false);
    setOpenLOkx(false);
    setOpenLKuc(false);
    setOpenL(false);
  };

  const navigation = useNavigation();

  const login = useGoogleLogin({

    onSuccess: (codeResponse) => {
      console.log(codeResponse);
      axios
        .post("/API/login",
          { gReply: codeResponse },
          {
            headers: {
              Accept: 'application/json'
            }
          })
        .then((res) => {
          if (res.status === 200) {
            setJwt(res.data);
            localStorage.setItem("jwt", res.data.access_token);
            const email = getEmail();
            notification["success"]({
              message: "Success",
              description: "You have logged in as " + email
            });
          }

        })
        .catch((err) => console.log(err));
    },
    onError: () => {
      notification["error"]({
        message: "Error",
        description: "Error login with google!",
      });
      console.log('Google login Failed');
    },
  });
  const submit = useSubmit();
  const logout = () => {
    submit(null, { method: "post", action: "/logout" });

    notification["success"]({
      message: "Success",
      description: "You are logged out now!",
    });
  }



  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open} sx={{ bgcolor: "#2e186a", zIndex: 1000 }}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"

              noWrap
              sx={{ flexGrow: 1 }}
            >
              <Link component={RouterLink} to={"/dashboard"} color="#fff" sx={{ "&:hover": { color: "rgb(255, 130, 92)", textUnderlinePosition: "under" } }} >
                Dashboard
              </Link>
            </Typography>
            {
              !jwt &&
              <IconButton color="inherit" onClick={login} sx={{"&:hover": { color: "rgb(255, 130, 92)", textUnderlinePosition: "under" } }}>
                <Badge badgeContent={"Login"} color="secondary">
                  <LoginIcon />
                </Badge>
              </IconButton>
            }
            {
              jwt &&
              <IconButton color="inherit" onClick={logout} sx={{ "&:hover": { color: "rgb(255, 130, 92)", textUnderlinePosition: "under" } }}>
                <Badge badgeContent={"Logout"} color="secondary">
                  <LogoutIcon />
                </Badge>
              </IconButton>
            }
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav" sx={{
            ...(!open && { display: 'none' }),
          }}>
            <ListItemButton onClick={handleClick}>

              <ListItemText primary="Binance" />
              {openL ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openL} timeout="auto" unmountOnExit>
              <List component="div" disablePadding dense={true}>
                <ListItemButton sx={{ pl: 2, pr: 1 }} component={RouterLink} to="/dashboard/5min/binance/usdt" selected={selectedEl === 0} >
                  <ListItemText primary="USDT 5m" />
                </ListItemButton>
                <ListItemButton
                  sx={{ pl: 2, pr: 1 }}
                  component={RouterLink}
                  to="/dashboard/bins/binance/usdt/"
                  selected={selectedEl === 3}
                >
                  <ListItemText primary="USDT Bins" />
                </ListItemButton>
                <ListItemButton
                  sx={{ pl: 2, pr: 1 }}
                  component={RouterLink}
                  to="/dashboard/dailycandles/binance/usdt/"
                  selected={selectedEl === 4}
                >
                  <ListItemText primary="USDT Range" />
                </ListItemButton>
                <ListItemButton
                  sx={{ pl: 2, pr: 1 }}
                  component={RouterLink}
                  to="/dashboard/5min/binance/busd"
                  selected={selectedEl === 1}
                >
                  <ListItemText primary="BUSD 5m" />
                </ListItemButton>
                <ListItemButton
                  sx={{ pl: 2, pr: 1 }}
                  component={RouterLink}
                  to="/dashboard/bins/binance/busd/"
                  selected={selectedEl === 2}
                >
                  <ListItemText primary="BUSD Bins" />
                </ListItemButton>
                <ListItemButton
                  sx={{ pl: 2, pr: 1 }}
                  component={RouterLink}
                  to="/dashboard/dailycandles/binance/busd/"
                  selected={selectedEl === 5}
                >
                  <ListItemText primary="BUSD Range" />
                </ListItemButton>
              </List>
            </Collapse>
            <ListItemButton onClick={handleClickKuc}>

              <ListItemText primary="Kucoin" />
              {openLKuc ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openLKuc} timeout="auto" unmountOnExit>
              <List component="div" disablePadding dense={true}>
                <ListItemButton sx={{ pl: 2, pr: 1 }} component={RouterLink} to="/dashboard/5min/kucoin/usdt" selected={selectedEl === 6} >
                  <ListItemText primary="USDT 5m" />
                </ListItemButton>
                <ListItemButton
                  sx={{ pl: 2, pr: 1 }}
                  component={RouterLink}
                  to="/dashboard/bins/kucoin/usdt/"
                  selected={selectedEl === 7}
                >
                  <ListItemText primary="USDT Bins" />
                </ListItemButton>
                <ListItemButton
                  sx={{ pl: 2, pr: 1 }}
                  component={RouterLink}
                  to="/dashboard/dailycandles/kucoin/usdt/"
                  selected={selectedEl === 8}
                >
                  <ListItemText primary="USDT Range" />
                </ListItemButton>
              </List>
            </Collapse>
            <ListItemButton onClick={handleClickOkx}>

              <ListItemText primary="OKX" />
              {openLOkx ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={(openLOkx)} timeout="auto" unmountOnExit>
              <List component="div" disablePadding dense={true}>
                <ListItemButton
                  sx={{ pl: 2, pr: 1 }}
                  component={RouterLink} to="/dashboard/5min/okx/usdt"
                  selected={selectedEl === 9} >
                  <ListItemText primary="USDT 5m" />
                </ListItemButton>
                <ListItemButton
                  sx={{ pl: 2, pr: 1 }}
                  component={RouterLink}
                  to="/dashboard/bins/okx/usdt/"
                  selected={selectedEl === 10}
                >
                  <ListItemText primary="USDT Bins" />
                </ListItemButton>
                <ListItemButton
                  sx={{ pl: 2, pr: 1 }}
                  component={RouterLink}
                  to="/dashboard/dailycandles/okx/usdt/"
                  selected={selectedEl === 11}
                >
                  <ListItemText primary="USDT Range" />
                </ListItemButton>
              </List>
            </Collapse>

            <ListItemButton onClick={handleClickBybit}>
              <ListItemText primary="Bybit" />
              {openLBybit ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={(openLBybit)} timeout="auto" unmountOnExit>
              <List component="div" disablePadding dense={true}>
                <ListItemButton
                  sx={{ pl: 2, pr: 1 }}
                  component={RouterLink} to="/dashboard/5min/bybit/usdt"
                  selected={selectedEl === 12} >
                  <ListItemText primary="USDT 5m" />
                </ListItemButton>
                <ListItemButton
                  sx={{ pl: 2, pr: 1 }}
                  component={RouterLink}
                  to="/dashboard/bins/bybit/usdt/"
                  selected={selectedEl === 13}
                >
                  <ListItemText primary="USDT Bins" />
                </ListItemButton>
                <ListItemButton
                  sx={{ pl: 2, pr: 1 }}
                  component={RouterLink}
                  to="/dashboard/dailycandles/bybit/usdt/"
                  selected={selectedEl === 14}
                >
                  <ListItemText primary="USDT Range" />
                </ListItemButton>
              </List>
            </Collapse>

            <ListItemButton onClick={handleClickGateIO}>
              <ListItemText primary="GateIO" />
              {openLGateIO ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={(openLGateIO)} timeout="auto" unmountOnExit>
              <List component="div" disablePadding dense={true}>
                <ListItemButton
                  sx={{ pl: 2, pr: 1 }}
                  component={RouterLink} to="/dashboard/5min/gateio/usdt"
                  selected={selectedEl === 15} >
                  <ListItemText primary="USDT 5m" />
                </ListItemButton>
                <ListItemButton
                  sx={{ pl: 2, pr: 1 }}
                  component={RouterLink}
                  to="/dashboard/bins/gateio/usdt/"
                  selected={selectedEl === 16}
                >
                  <ListItemText primary="USDT Bins" />
                </ListItemButton>
                <ListItemButton
                  sx={{ pl: 2, pr: 1 }}
                  component={RouterLink}
                  to="/dashboard/dailycandles/gateio/usdt/"
                  selected={selectedEl === 17}
                >
                  <ListItemText primary="USDT Range" />
                </ListItemButton>
              </List>
            </Collapse>

          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          {props.children}
          {navigation.state === 'loading' &&
            <Box sx={{ width: '100%' }}>
              <LinearProgress />
              <p style={{ textAlign: 'center' }}>Loading...</p>
            </Box>}
          <Outlet />
          <Copyright sx={{ pt: 4 }} />
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default function Dashboard(props) {

  return <>
    <DashboardContent>
      {props.children}
    </DashboardContent>
  </>
}
