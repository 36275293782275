import jwt_decode from "jwt-decode";
import { redirect } from "react-router";

export function getAuthToken() {
    const jwt = localStorage.getItem('jwt');

    if (jwt !== null) {
        return jwt;
    }
    return null;
}

export function getEmail() {
    const jwt = getAuthToken();
    if (jwt == null) {
        return "NOTLOGGED"
    }
    const email = jwt_decode(jwt).email;
    return email;
}

export function getRole() {
    const jwt = getAuthToken();
    if (jwt == null) {
        return "NOTLOGGED"
    }
    const role = jwt_decode(jwt).role;
    return role;

}

export function checkAuthLoader() {
    const token = getAuthToken();
    if (!token) {
        return redirect('/');
    }
}