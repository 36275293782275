import Alert from '@mui/material/Alert';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Dashboard from '../Dashboard';
import { useRouteError } from 'react-router-dom';

function ErrorPageDashboard() {
    console.log("Render ErrorPageDashboard()");
    const error = useRouteError();
    console.log(error)
    let mess = "Something went wrong!";
    if (error.status === 500) {
        mess = JSON.parse(error.data).message;
    }
    if (error.status === 404) {
        mess = "Could not find resource or page!";
    }
    if (error.status === 422) {
        mess = JSON.parse(error.data).message;
    }
    if (error.status === 401) {
        mess = JSON.parse(error.data).message;
    }
    return (
        <Dashboard>
            <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
                <Paper sx={{
                    pl: 10,
                    paddingBottom: 10,
                    paddingTop: 10
                }}>
                    <Alert severity="error"> {mess} </Alert>
                </Paper>
            </Container>
        </Dashboard>

    )

}

export default ErrorPageDashboard;