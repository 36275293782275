import { lazy } from "react";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { useRouteError } from 'react-router-dom';
import { Styles } from "../../styles/styles";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
const Container = lazy(() => import("../../common/Container"));

function ErrorPage() {
    console.log("Render ErrorPage()");
    const error = useRouteError();
    let mess = "Something went wrong!";
    
    if (error.status === 500 || error.status === 401 || error.status === 402 ) {
        mess = JSON.parse(error.data).message;
    }
    if (error.status === 404) {
        mess = "Could not find resource or page!";
    }
    if (error.status === 422) {
        mess = "Please login! Session expired!";
    }

    return (
        <>
            <Styles />
            <Header />
            <Container>
                <Stack sx={{ width: '100%' }} spacing={2}>
                    <Alert severity="error"> {mess} </Alert>
                </Stack>
            </Container>
            <Footer />
        </>


    )

}

export default ErrorPage;