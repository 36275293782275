import { useState, useEffect } from "react";
import axios from 'axios';
import { useGoogleLogin } from '@react-oauth/google';
import { Row, Col, Drawer } from "antd";
import { withTranslation } from "react-i18next";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";
import { Button } from "../../common/Button";
import { getEmail } from "../../common/utils/auth";
import { notification } from "antd";
import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
} from "./styles";
import { useNavigate } from "react-router-dom";
import { useSubmit, useLocation } from "react-router-dom";
import { getAuthToken } from "../../common/utils/auth";


const Header = ({ t }: any) => {

  const navigate = useNavigate();

  const [visible, setVisibility] = useState(false);

  const showDrawer = () => {
    setVisibility(!visible);
  };

  const onClose = () => {
    setVisibility(!visible);
  };

  const MenuItem = () => {
    const scrollTo = (id: string) => {
      const element = document.getElementById(id) as HTMLDivElement;
      element.scrollIntoView({
        behavior: "smooth",
      });
      setVisibility(false);
    };
    const  [jwt, setJwt]  = useState(getAuthToken());
    const [gReply, setGReply] = useState({});

    const login = useGoogleLogin({
      onSuccess: (codeResponse) => {
        console.log(codeResponse);
        setGReply(codeResponse);
      },
      onError: () => {
        notification["error"]({
          message: "Error",
          description: "Error login with google!",
        });
        console.log('Google login Failed');
      },
    });
    const submit = useSubmit();
    const logout = () => {
      submit(null, { method: "post", action: "/logout" });
      //setJwt(null);
      notification["success"]({
        message: "Success",
        description: "You are logged out now!",
      });
    }

    //
    useEffect(
      () => {
        if (gReply && Object.keys(gReply).length !== 0) {
          console.log("useEffect Posting token");
          axios
            .post("/API/login",
              { gReply: gReply },
              {
                headers: {
                  Accept: 'application/json'
                }
              })
            .then((res) => {
              if (res.status === 200) {
                setJwt(res.data);
                localStorage.setItem("jwt", res.data.access_token);
                const email = getEmail();
                notification["success"]({
                  message: "Success",
                  description: "You have logged in as " + email
                });
              }

            })
            .catch((err) => console.log(err));
        }
      },
      [gReply, setJwt]
    );
    const location = useLocation();
    const onHeaderLinkClick = (part: string)  => {
      if (location.pathname === "/"){
        scrollTo(part);
      } else {
        navigate("/", { state: part });
      }
      
    }
    console.log(location)

    return (
      <>
        <CustomNavLinkSmall onClick={() => onHeaderLinkClick("about")}>
          <Span>{t("About")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => onHeaderLinkClick("mission")}>
          <Span>{t("Mission")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => onHeaderLinkClick("product")}>
          <Span>{t("Product")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => onHeaderLinkClick("contact")}>
          <Span>{t("Contact")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => navigate('/wiki')}>
          <Span>{t("Wiki")}</Span>
        </CustomNavLinkSmall>
        {
          !jwt &&
          <CustomNavLinkSmall onClick={() => login()}>
            <Span>{t("Login")}</Span>
          </CustomNavLinkSmall>
        }
        {
          jwt &&
          <CustomNavLinkSmall onClick={() => logout()}>
            <Span>{t("Logout")}</Span>
          </CustomNavLinkSmall>

        }
        <CustomNavLinkSmall
          style={{ width: "180px" }}
          onClick={() => navigate('/dashboard')}
        >
          <Span>
            <Button>{t("Dashboard")}</Button>
          </Span>
        </CustomNavLinkSmall>
      </>
    );
  };

  return (
    <HeaderSection>
      <Container>
        <Row justify="space-between">
          <LogoContainer to="/" aria-label="homepage">
            <SvgIcon src="logo-no-bg.svg" width="101px" height="64px" />
          </LogoContainer>
          <NotHidden>
            <MenuItem />
          </NotHidden>
          <Burger onClick={showDrawer}>
            <Outline />
          </Burger>
        </Row>
        <Drawer closable={false} open={visible} onClose={onClose}>
          <Col style={{ marginBottom: "2.5rem" }}>
            <Label onClick={onClose}>
              <Col span={12}>
                <Menu>Menu</Menu>
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItem />
        </Drawer>
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
