import ReactDOM from "react-dom/client";
import { Suspense, lazy } from 'react';
import { StrictMode, useEffect } from "react";
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { GoogleOAuthProvider } from '@react-oauth/google';

import { I18nextProvider } from "react-i18next";
import "antd/dist/antd.min.css";

import i18n from "./translation";

import Home from "./pages/Home";

import ErrorPage from "./pages/ErrorPage";
import ErrorPageDashboard from "./pages/ErrorPageDashboard";
import { action as logoutAction } from './pages/logout';
import ReactGA from "react-ga4";

const TRACKING_ID = "G-R1DX3KVB2S"; // OUR_TRACKING_ID

ReactGA.initialize(TRACKING_ID);

if (process.env.NODE_ENV === 'production') {

  console.log = () => { }
  console.error = () => { }
  console.debug = () => { }
}

const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <Home />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/terms",
      Component: lazy(() => import('./pages/Terms')),
    },
    {
      path: "/wiki",
      Component: lazy(() => import('./pages/Wiki')),
    },
    {
      path: "/about",
      Component: lazy(() => import('./pages/About')),
    },
    {
      path: '/dashboard',
      Component: lazy(() => import('./pages/Dashboard')),
      errorElement: <ErrorPageDashboard />,
      children: [
        { index: true, lazy: () => import('./pages/DashboardStartPage') },
        { path: '5min/:exchange/:quote', lazy: () => import('./pages/FiveMinView') },
        { path: 'bins/:exchange/:quote/:limit?', lazy: () => import('./pages/BinView') },
        { path: 'dailycandles/:exchange/:quote', lazy: () => import('./pages/DailyCandlesView') },
      ]
    },
    {
      path: '/logout',
      action: logoutAction
    }
  ]
);

const App = () => {

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "Cryptokis volatility scanner" });
  }, []);

  return (
    <I18nextProvider i18n={i18n}>
      <Suspense fallback={<>Loading...</>}>
        <RouterProvider router={router} />
      </Suspense>
    </I18nextProvider>
  )

};

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  <StrictMode>
    <GoogleOAuthProvider clientId="431966907484-mjafq3fl5gfg29h406tt7nkjbg3injvi.apps.googleusercontent.com">
      <App />
    </GoogleOAuthProvider>
  </StrictMode>
);